import React from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { fileTypesToAcceptString } from './utils'
import { FileUploadReducer, createFileUploaderReducer, createFileUploaderValidation, useFileUploader } from './useFileUploader'
import { AcceptedFileTypes } from './types'
import { useTranslation } from 'react-i18next'
import { useHumanReadableFilesize } from './use-human-readable-filesize'
import { FileUploaderBase } from './FileUploaderBase'

export interface Props
  extends Partial<Pick<React.ComponentProps<typeof FileUploaderBase>, 'PaperComponent' | 'multiple' | 'accept' | 'maximumFilesize'>> {
  /**
   * This prop is invoked when files state changes, e.g. on selecting a file.
   */
  onChange?: (files: File[], callback: (files: File[]) => void) => void
  /**
   * This prop can be provided to alter behavior of state change.
   */
  reducer?: FileUploadReducer
}

export const ImageUploadButton: React.FC<Props> = ({
  multiple = true,
  maximumFilesize = 5,
  accept = ['jpg', 'jpeg', 'png', 'svg'] as AcceptedFileTypes[],
  reducer = createFileUploaderReducer(createFileUploaderValidation(multiple, accept, maximumFilesize)),
  onChange
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const formatter = useHumanReadableFilesize()
  const { files, setFiles, appendFiles, error } = useFileUploader(reducer)

  const inputRef = React.useRef<HTMLInputElement>(null)

  function updateFiles(fileList: FileList) {
    const files = Array.from(fileList)
    appendFiles(files)
  }

  React.useEffect(() => {
    if (files.length === 0) {
      inputRef.current && (inputRef.current.value = '')
    }
    onChange && onChange(files, setFiles)
  }, [files])

  return (
    <Box sx={{ mt: 2, textAlign: 'center' }}>
      <Button variant='outlined' color='primary' onClick={() => inputRef.current && inputRef.current.click()}>
        {t('FileUploader.SelectProfileImage')}
        <input
          data-testid='file-upload-input'
          ref={inputRef}
          style={{ display: 'none' }}
          accept={fileTypesToAcceptString(accept)}
          multiple={multiple}
          type='file'
          onChange={event => {
            if (event.target.files && event.target.files.length > 0) {
              updateFiles(event.target.files)
            }
          }}
        />
      </Button>

      {error && (
        <Box mt={1} textAlign='left'>
          <Typography variant='body2' fontSize='0.85rem' color={theme.palette.error.main}>
            {t(error)}
          </Typography>
        </Box>
      )}

      <Box mt={2} textAlign='center'>
        <Typography variant='body2' color='textSecondary'>
          {t('FileUploader.AcceptedFormats')}
          {fileTypesToAcceptString(accept)}.&nbsp;&nbsp;{t('FileUploader.Max_size')}&nbsp;{formatter(maximumFilesize)}.
        </Typography>
      </Box>
    </Box>
  )
}
